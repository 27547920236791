import React, { useEffect, useState } from "react";
import img from "../components/image/Screenshot_1.png";
import { BsFillHeartFill } from "react-icons/bs";
import { SlMenu } from "react-icons/sl";
import useEvenStore from "../../utils/store";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IoIosNotificationsOutline } from "react-icons/io";
import axios from "axios";
// const userInfo = window.localStorage.getItem("user-info");
const userInfo = JSON.parse(window.localStorage.getItem("user-info"));
const idUser = userInfo?.id_user;
console.log(idUser);
// function reverseSortByDateTime(notifications) {
//   return notifications.slice().sort((a, b) => {
//     const timeA = new Date(a.time);
//     const timeB = new Date(b.time);
//     return timeB - timeA;
//   });
// }

function Header({ onSearchChange, onSearch, onClick }) {
  const [showMenu, setShowMenu] = useState(false);
  const version = useEvenStore((state) => state.version);
  const setVersion = useEvenStore((state) => state.setVersion);
  const navigate = useNavigate();
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);
  const [fetchSuccess, setFetchSuccess] = useState(false);

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  useEffect(() => {
    axios
      .get(`https://databaseswap.mangasocial.online/notification/${idUser}`)
      .then((response) => {
        setNotifications(response.data);
        setFetchSuccess(true); // Đánh dấu fetching thành công
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
        setError(error); // Lưu thông tin lỗi vào state
        setFetchSuccess(false); // Đánh dấu fetching thất bại
      });
  }, []);

  const user = window.localStorage.getItem("user-info");
  const BackHome = () => {
    navigate("/");
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const toggleVersion = () => {
    navigate("/love");
  };
  const onChangeSearch = (event) => {
    onSearch(event.target.value);
  };

  return (
    <div className="w-full h-40 py-3 lg:py-7">
      {/* fixed top-0 left-0  z-20 */}
      <div className="flex items-center justify-between">
        {/* logo */}
        <div className="flex items-center">
          <img
            src={img}
            alt=""
            className="w-24 h-20 lg:w-28 lg:h-24"
            onClick={BackHome}
          />
          <p
            className="hidden xl:inline-flex items-center text-3xl text-white lg:text-6xl starborn"
            onClick={BackHome}
          >
            FUTURE LOVE
          </p>
          <img
            src={img}
            alt=""
            className="hidden xl:block w-24 h-20 lg:w-28 lg:h-24"
          />
        </div>
        <div className="flex justify-center items-center gap-1 bg-[linear-gradient(165deg,#ea20b7_0%,#ee747c_50%,#d080c8_100%)] rounded-3xl p-2 lg:p-4">
          <div className="flex justify-center items-center w-12 h-12 lg:w-20 lg:h-20">
            <Link to={"https://www.youtube.com/@FutureLoveApplication-tl5ch"}>
              <img
                src="https://www.freeiconspng.com/thumbs/youtube-logo-png/hd-youtube-logo-png-transparent-background-20.png"
                alt=""
                className="w-8 h-8 lg:w-12 lg:h-12 hover:scale-105 transition-all cursor-pointer"
              />
            </Link>
          </div>
          <div className="flex justify-center items-center w-12 h-12 lg:w-20 lg:h-20">
            <Link
              to={
                "https://www.tiktok.com/@futurelove430?is_from_webapp=1&sender_device=pc"
              }
            >
              <img
                src="https://static.vecteezy.com/system/resources/thumbnails/016/716/450/small/tiktok-icon-free-png.png"
                alt=""
                className="w-8 h-8 lg:w-12 lg:h-12 hover:scale-105 transition-all cursor-pointer"
              />
            </Link>
          </div>
          <div className="flex justify-center items-center w-12 h-12 lg:w-20 lg:h-20">
            <Link
              to={
                "https://play.google.com/store/apps/details?id=com.thinkdiffai.futurelove"
              }
            >
              <img
                src="https://i.rada.vn/data/image/2022/08/02/Google-Play-Store-200.png"
                alt=""
                className="w-8 h-8 lg:w-12 lg:h-12 hover:scale-105 transition-all cursor-pointer"
              />
            </Link>
          </div>
          <div className="flex justify-center items-center w-12 h-12 lg:w-20 lg:h-20">
            <Link
              to={
                "https://apps.apple.com/us/app/futurelove-ai-love-future/id6463770787"
              }
            >
              {" "}
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/6/67/App_Store_%28iOS%29.svg"
                alt=""
                className="w-8 h-8 lg:w-12 lg:h-12 hover:scale-105 transition-all cursor-pointer"
              />
            </Link>
          </div>
        </div>

        {/* search */}
        <div className="hidden sm:block">
          <div className="flex items-center i-search">
            <i className="absolute ml-8 text-3xl text-gray-500 fa fa-search" />
            <input
              type="search"
              placeholder="Search"
              className="h-20 rounded-full searchTerm w-search"
              onChange={onChangeSearch}
            />
          </div>
        </div>

        {/* menu */}

        <div className="flex">
          {idUser ? (
            <div className="relative">
              <IoIosNotificationsOutline
                className="lg:text-[56px] text-[38px] text-white mt-1 font-black mr-10 cursor-pointer transition-transform duration-300 hover:scale-125"
                onClick={toggleNotifications}
              />
              {/* {notifications.So_luong_thong_bao_chua_doc > 0 && (
                <span className="absolute flex items-center justify-center w-10 h-10 text-xl text-white bg-red-500 rounded-full -top-2 right-10">
                  {notifications.So_luong_thong_bao_chua_doc}
                </span>
              )} */}
            </div>
          ) : (
            <span></span>
          )}
          <BsFillHeartFill
            onClick={toggleVersion}
            className="lg:text-[54px] text-[38px] text-white mt-2 lg:mr-10 mr-5 transition-transform duration-300 hover:scale-125 cursor-pointer"
          />
          <SlMenu
            className="lg:text-[56px] text-[38px] text-white mt-1 font-black mr-10 cursor-pointer transition-transform duration-300 hover:scale-125"
            onClick={() => {
              setShowMenu(!showMenu);
            }}
          />
        </div>
      </div>
      {/* navLink */}
      {showNotifications && (
        <div
          className={`absolute top-36 right-10 z-50 bg-[#FFF2EB] rounded-[16px] shadow-lg p-4 transition-all duration-300 font-[Montserrat] ${
            fetchSuccess && notifications.comment > 0
              ? "w-[400px]"
              : "w-[400px]"
          } ${
            fetchSuccess && notifications.comment > 0 ? "h-[300px]" : "h-[80px]"
          }`}
        >
          <h2 className="bg-[#FF6B3D] text-white py-2 px-4 rounded-t-[16px] text-center text-2xl">
            Notifications
          </h2>
          {error ? (
            <p className="mt-4 text-lg text-red-500">
              Error fetching notifications: {error.message}
            </p>
          ) : (
            <ul className="w-full h-[250px] overflow-y-auto">
              {notifications?.comment?.map((notification, index) => {
                const time = new Date(notification.time);
                const formattedTime = `${time.getHours()}:${time.getMinutes()} day ${time.getDate()}/${
                  time.getMonth() + 1
                }`;
                return (
                  <li
                    key={index}
                    className="py-3 text-left border-b border-gray-300"
                  >
                    <div className="flex items-center">
                      <div className="w-12 h-12 rounded-full bg-[#FF6B3D] flex justify-center items-center text-white font-semibold text-lg mr-4">
                        {notification.user_name_cmt
                          ? notification.user_name_cmt.charAt(0)
                          : "G"}
                      </div>
                      <div>
                        <p className="text-2xl font-semibold">
                          {notification.user_name_cmt
                            ? notification.user_name_cmt
                            : "Guest"}
                        </p>
                        <p className="text-xl text-gray-500 ">
                          {formattedTime}
                        </p>
                      </div>
                    </div>
                    <p className="mt-2 text-2xl">{`commented on your post`}</p>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}

      {showMenu && (
        <div className="absolute z-50 top-36 right-10 w-96">
          <ul>
            {user && (
              <>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center rounded-t-[16px]">
                  <NavLink
                    className="slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/"
                  >
                    HOME
                  </NavLink>
                </li>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center">
                  <NavLink
                    className="pt-16 slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/viewEvent"
                  >
                    EVENTS
                  </NavLink>
                </li>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center">
                  <NavLink
                    className="pt-16 slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/Profile"
                  >
                    PROFILE
                  </NavLink>
                </li>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center">
                  <NavLink
                    className="pt-16 slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/video"
                  >
                    VIDEO
                  </NavLink>
                </li>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center">
                  <NavLink
                    className="pt-16 slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/Policy"
                  >
                    POLICY
                  </NavLink>
                </li>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center">
                  <NavLink
                    className="pt-16 slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/listvideo"
                  >
                    LISTVIDEO
                  </NavLink>
                </li>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center">
                  <NavLink
                    className="pt-16 slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/gensk"
                  >
                    GenBaBy
                  </NavLink>
                </li>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center">
                  <NavLink
                    className="pt-16 slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/genimg"
                  >
                    Swap2Img
                  </NavLink>
                </li>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center">
                  <NavLink
                    className="pt-16 slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/genvideo"
                  >
                    SwapVideo
                  </NavLink>
                </li>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center">
                  <NavLink
                    className="pt-16 slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/about-company"
                  >
                    ABOUT
                  </NavLink>
                </li>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center rounded-b-[16px]">
                  <NavLink
                    onClick={handleLogout}
                    className="pt-16 slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-gray-300 hover:text-gray-500
             "
                    to="/"
                  >
                    LOGOUT
                  </NavLink>
                </li>
              </>
            )}
            {!user && (
              <>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center rounded-t-[16px]">
                  <NavLink
                    className="slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/"
                  >
                    HOME
                  </NavLink>
                </li>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center">
                  <NavLink
                    className="pt-16 slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/video"
                  >
                    VIDEO
                  </NavLink>
                </li>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center">
                  <NavLink
                    className="pt-16 slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/NewHistory"
                  >
                    EVENTS
                  </NavLink>
                </li>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center">
                  <NavLink
                    className="pt-16 slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/Policy"
                  >
                    POLICY
                  </NavLink>
                </li>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center">
                  <NavLink
                    className="pt-16 slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/gensk"
                  >
                    GenBaBy
                  </NavLink>
                </li>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center">
                  <NavLink
                    className="pt-16 slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/genimg"
                  >
                    Swap2Img
                  </NavLink>
                </li>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center">
                  <NavLink
                    className="pt-16 slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/genvideo"
                  >
                    SwapVideo
                  </NavLink>
                </li>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center">
                  <NavLink
                    className="pt-16 slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/listvideo"
                  >
                    LISTVIDEO
                  </NavLink>
                </li>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center">
                  <NavLink
                    className="pt-16 slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/about-company"
                  >
                    ABOUT
                  </NavLink>
                </li>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center">
                  <NavLink
                    className="pt-16 slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/login"
                  >
                    LOGIN
                  </NavLink>
                </li>
                <li className="w-full bg-[#FFF2EB] h-32 flex justify-center items-center rounded-b-[16px]">
                  <NavLink
                    className="slab font-extrabold text-[36px]  text-[#FF2C61] px-8 py-2 rounded-2xl hover:bg-[#ED709D] hover:text-white
             "
                    to="/register"
                  >
                    REGISTER
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Header;
